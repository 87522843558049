<template>
	<!-- 新建课程页面 -->
	<div class="positionT0L0">
		<p class="title"><span></span> 基本信息</p>
		<a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 7 }">
			<a-form-model-item label="课程名称" prop="name">
				<a-input v-model="form.name" placeholder="请输入课程名称" :maxLength="20">
					<div slot="addonAfter">{{ form.name.length }}/20</div>
				</a-input>
			</a-form-model-item>
			<a-form-model-item label="一句话介绍" prop="conclusion">
				<a-input v-model="form.conclusion" placeholder="请输入一句话介绍" :maxLength="22">
					<div slot="addonAfter">{{ form.conclusion.length }}/22</div>
				</a-input>
			</a-form-model-item>
			<a-form-model-item label="课程简介" prop="brief">
				<a-textarea placeholder="请输入课程简介" v-model="form.brief" :maxLength="300" :rows="5"></a-textarea>
				<span class="form_span">{{ form.brief.length }}/300</span>
			</a-form-model-item>
			<a-form-model-item label="商家编码" placeholder="请输入商家编码">
				<a-input v-model="form.commodityCode" placeholder="请输入商家编码" :maxLength="50">
					<div slot="addonAfter">{{ form.commodityCode.length }}/50</div>
				</a-input>
				<div class="tips">商家对该课程自定义的编码(唯一)</div>
			</a-form-model-item>
			<a-form-model-item label="课程封面" prop="coverUrl">
				<!-- <CropperUpload :imageUrl="form.coverUrl" :options="coverOptions" @avatarfn="coverUrlFn" avatarTextTop="推荐使用174px*200px  JPG.PNG.JPEG格式 图片小于1M" avatarTextBottom=""/> -->
				<UpdataImg @avatarfns="coverUrlFn" :imageUrlNum="1" :imgUrl="coverUrl"></UpdataImg>
			</a-form-model-item>
			<a-form-model-item label="主讲老师" prop="masterTeacherNo">
				<a-select show-search v-model="form.masterTeacherNo" placeholder="点击选择主讲老师" option-filter-prop="children" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="masterTeacherChange">
					<a-select-option :value="item.labelNo" v-for="item in masterTeacherArr" :key="item.labelNo">{{ item.labelText }}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="课程详情封面">
				<detailCropperUpload :imageUrl="form.detailsUrl" :options="detailOptions" @avatarfn="detailsUrlFn" avatarTextTop="建议尺寸750px*550px,JPG.PNG.JPEG格式 图片小于1M" avatarTextBottom="" />
			</a-form-model-item>
			<a-form-model-item label="课程标签" prop="labels">
				<a-select mode="multiple" style="width: 100%" :filterOption="filterOption" v-model="form.labels" placeholder="请选择课程标签" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="labelsChange">
					<a-select-option v-for="item in labelsArr" :key="item.labelNo">{{ item.labelText }}</a-select-option>
				</a-select>
				<span class="tag-label-refresh" @click="onRefresh">刷新</span>
				<span class="tag-label-page" v-show="isShowCourseTag">
					<router-link tag="a" target="_blank" to="/coursManage/courseTag">前往标签管理<a-icon type="right" :style="{ fontSize: '10px' }" /></router-link>
				</span>
			</a-form-model-item>
			<a-form-model-item label="课程类型" prop="courseType">
				<a-radio-group :disabled="$route.params.id != 0" name="radioGroup" :value="form.courseType" @change="courseTypeChange">
					<a-radio :value="1"> 伴读课</a-radio>
					<a-radio :value="2"> 网课</a-radio>
				</a-radio-group>
				<div class="tips">课程类型只能选择一次，保存后不能修改，请慎重选择。</div>
			</a-form-model-item>
			<a-form-model-item label="是否分章节" prop="catalogueFlag">
				<a-radio-group name="radioGroup" :value="form.catalogueFlag" @change="catalogueFlagChange">
					<a-radio :value="1"> 是</a-radio>
					<a-radio :value="0"> 否</a-radio>
				</a-radio-group>
			</a-form-model-item>
			<a-form-model-item label="课程详情" prop="description" :wrapper-col="{ span: 16 }">
				<Ueditor id="editor-with-xiumi0" :content="form.description" @editorFn="editorFn" />
			</a-form-model-item>
			<a-form-model-item label="亮点介绍" id="tab1Editor2" :wrapper-col="{ span: 16 }">
				<Ueditor :content="form.highlights" id="editor-with-xiumi1" @editorFn="highlightseditorFn" />
			</a-form-model-item>
			<a-form-model-item label="课程亮点" id="tab1Editor2" prop="highlights" :wrapper-col="{ span: 16 }">
				<template v-for="(item, i) in highlightsNew">
					<div class="listPs">
						<p style="display: flex">
							<a-input v-model="item.title" placeholder="请输入标题" style="margin-bottom:10px"></a-input>
							<a-icon type="minus-circle" v-if="i != 0" style="margin-left: 10px;margin-top: 10px" @click="highlightsNew.splice(i, 1)" />
						</p>
						<a-form-item name="price" label="">
							<a-textarea v-model="item.content" placeholder="请输入内容" :rows="4" />
						</a-form-item>
					</div>
				</template>
				<div style="display: flex;justify-content: flex-end">
					<a-button type="primary" @click="highlightsNewClick">新增</a-button>
				</div>
			</a-form-model-item>
			<a-form-model-item label="好句分享" prop="goodSentence" :wrapper-col="{ span: 16 }">
				<UpdataImg @avatarfns="goodSentenceUrlFn" :imageUrlNum="5" :imgUrl="goodSentence"></UpdataImg>
			</a-form-model-item>
			<a-form-model-item label="精彩选段" prop="wonderfulExcerpts" :wrapper-col="{ span: 16 }">
				<Ueditor :content="form.wonderfulExcerpts" id="editor-with-xiumi2" @editorFn="wonderfulExcerptseditorFn" />
			</a-form-model-item>
			<a-form-model-item label="旧版课程大纲" :wrapper-col="{ span: 16 }">
				<UpdataImg @avatarfns="syllabusUrlFn" :imageUrlNum="1" :imgUrl="syllabus"></UpdataImg>
			</a-form-model-item>
			<a-form-model-item label="新版课程大纲" :wrapper-col="{ span: 16 }">
				<UpdataImg @avatarfns="syllabusUrlFnNew" :imageUrlNum="15" :imgUrl="syllabusNew"></UpdataImg>
			</a-form-model-item>
			<a-alert message="学习手册两项内容，或不输入或全部输入" type="error" style="width: 500px" />
			<a-form-model-item label="学习手册">
				<a-input v-model="form.studyGuide" placeholder="请输入App显示的名称" :maxLength="14">
					<div slot="addonAfter">{{ form.studyGuide.length }}/14</div>
				</a-input>
				<UpdataImg @avatarfns="studyGuideUrlFnNew" :imageUrlNum="1" :urlSize="2048" :imgUrl="studyGuideUrl"></UpdataImg>
			</a-form-model-item>
			<a-form-model-item label="推荐课程" prop="courseRem">
				<a-input v-model="form.courseRem" placeholder="请输入推荐课程"></a-input>
			</a-form-model-item>
			<a-form-model-item label="适合人群" :wrapper-col="{ span: 16 }">
				<Ueditor :content="form.suitableForPeople" id="editor-with-xiumi4" @editorFn="suitableForPeopleeditorFn" />
			</a-form-model-item>
			<a-form-model-item label="购买须知" :wrapper-col="{ span: 16 }">
				<Ueditor :content="form.purchaseInstructions" id="editor-with-xiumi5" @editorFn="purchaseInstructionseditorFn" />
			</a-form-model-item>
			<a-form-model-item label="伴读资料链接" prop="pairedReadingUrl">
				<a-input v-model="form.pairedReadingUrl" placeholder="请输入伴读资料链接"></a-input>
			</a-form-model-item>
			<a-form-model-item label="包含课程" prop="unionCourse" :wrapper-col="{ span: 18, offset: 0 }">
				<template v-for="(item, i) in form.unionCourse">
					<p class="listP">
						<a-input v-model:value="item.courseNo" placeholder="请输入课程ID" style="width: 200px"></a-input>
						<a-form-item name="price" label="实际售价">
							<a-input-number v-model="item.price" maxLength="5" :min="0" :max="99999.99" :precision="2"
								placeholder="请输入实际售价" style="width: 120px" />
						</a-form-item>
						<a-form-item name="linePrice" label="划线价格">
							<a-input-number v-model="item.linePrice" maxLength="5" :min="item.price" :max="99999.99" :precision="2"
								placeholder="请输入划线价格" style="width: 120px" />
						</a-form-item>
					</p>
				</template>
			</a-form-model-item>
			<a-form-model-item label="排序" prop="sort">
				<a-input-number v-model="form.sort" :min="0" :max="99999" placeholder="排序" class="width100" />
			</a-form-model-item>
			<p class="title"><span></span> 销售信息</p>
			<a-form-model-item label="定价方式" prop="pricingType" style="margin-bottom: 0px">
				<a-radio-group name="radioGroup" :value="form.pricingType" @change="pricingTypeChange">
					<a-radio :value="1"> 付费</a-radio>
					<a-radio :value="0"> 免费</a-radio>
					<a-radio :value="2">不支持单独售卖</a-radio>
				</a-radio-group>
			</a-form-model-item>
			<p class="price marign_bottom24" v-show="form.pricingType != 2">
				<span v-if="form.pricingType !== 0">销售价格
					<a-input-number v-model="form.price" maxLength="5" :min="0" :max="99999.99" :precision="2" placeholder="请输入价格" style="width: 120px" />元
				</span>
				<span>划线价格
					<a-input-number v-model="form.linePrice" maxLength="5" :min="0" :max="99999.99" :precision="2" placeholder="请输入价格" style="width: 120px" />元
				</span>
			</p>
			<div class="study" v-show="form.pricingType == 2">不支持单独售卖的课程只能通过会员卡的形式让用户进行学习</div>
			<a-form-model-item label="课程有效期" prop="enableFlag">
				<div>
					<a-radio-group name="radioGroup" :value="form.enableFlag" @change="enableFlagChange">
						<a-radio :value="1"> 永久有效</a-radio>
						<a-radio :value="0"> 非永久有效</a-radio>
					</a-radio-group>
				</div>
				<div v-show="form.enableFlag == 0">
					<a-input-number v-model="form.validDayCount" :min="0" :max="99999" :precision="0" placeholder="请输入有效天数" class="width100" />天
				</div>
			</a-form-model-item>
			<a-form-model-item label="虚拟已购人数" prop="studyCount">
				<a-input-number v-model="form.studyCount" :min="0" :max="99999" :precision="0" placeholder="请输入人数" class="width100" />人
			</a-form-model-item>
			<p class="title"><span></span> 上架设置</p>
			<a-form-model-item ref="putawayFlag	" label="上架设置" prop="putawayFlag">
				<a-radio-group :value="form.putawayFlag" @change="putawayFlagChange">
					<a-radio :style="radioStyle" :value="2">立即上架</a-radio>
					<a-radio :style="radioStyle11" :value="1"> 定时上架</a-radio>
					<template v-if="form.putawayFlag == 1">
						<template>
							<a-date-picker :locale="locale" :value="form.putawayTime ? form.putawayTime : null" :format="dateFormat" show-time placeholder="请选择上架时间" @change="putawayTimeChange" @ok="putawayTimeOk" />
						</template>
					</template>
					<a-radio :style="radioStyle" :value="3"> 暂不上架</a-radio>
				</a-radio-group>
			</a-form-model-item>
			<p class="title"><span></span> 第三方信息</p>
			<a-form-model-item label="抖店商品编码" prop="trillCommodityCode">
				<a-input v-model="form.trillCommodityCode" placeholder="请输入抖店商品编码" />
				<div class="tips">抖店为该课程的生成的唯一的商品编码(或叫抖店商品ID)</div>
			</a-form-model-item>
			<p class="title"><span></span> 助教二维码</p>
			<a-alert message="以下三项，或不输入或全部输入" type="error" style="width: 500px" />
			<a-form-model-item label="标题">
				<a-input v-model="form.assistantTitle" :maxLength="120" placeholder="请输入标题" />
			</a-form-model-item>
			<a-form-model-item label="引导文案" prop="trillCommodityCode">
				<a-input v-model="form.assistantDes" :maxLength="120" placeholder="请输入引导文案" />
			</a-form-model-item>
			<a-form-model-item label="助教二维码" prop="assistantQRCodeImg">
				<!-- <detailCropperUpload :imageUrl="form.assistantQRCodeImg" :options="assistantQRCodeImgOptions" @avatarfn="assistantQRCodeImgUrlFn" avatarTextTop="建议尺寸550px*550px,JPG.PNG.JPEG格式 图片小于1M" avatarTextBottom=""/>-->
				<UpdataImg @avatarfns="assistantQRCodeImgUrlFn" :imageUrlNum="1" :imgUrl="assistantQRCodeImg"></UpdataImg>
				<span>建议尺寸550px*550px,JPG.PNG.JPEG格式 图片小于1M</span>
			</a-form-model-item>
			<a-form-model-item label="是否实物" prop="whetherKind">
				<a-radio-group :value="form.whetherKind" @change="whetherKindChange">
					<a-radio :value="0">否</a-radio>
					<a-radio :value="1">是</a-radio>
				</a-radio-group>
			</a-form-model-item>
		</a-form-model>

		<FooterToolBar :collapsed="sideCollapsed">
			<a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
			<a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
		</FooterToolBar>
	</div>
</template>

<script>
import MyUpload from "@/components/file/MyUpload";
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import { format } from "@/utils/tools";
import {
	CourseTeacherSelectApi,
	GetAllLabelsByTypeApi,
	CourseGetCourseInfoApiV2,
	CourseInfoSaveCourseApiv2,
	CourseInfoUpdCourseApiv2,
} from "@/request/api/coursManage";
import CropperUpload from "@/components/file/CropperUpload";
import UpdataImg from "@/components/file/updataImg";
import Ueditor from "@/components/ueditor/ueditor";
import { codeFn } from "@/utils/tools";

export default {
	mixins: [baseMixin],
	created () {
		let id = this.$route.params.id;
		if (id !== "0") {
			this.form.id = id;
		}
		if (id !== "0") {
			CourseGetCourseInfoApiV2({ courseNo: id }).then(({ code, data }) => {
				if (code == 200) {
					this.form = data;

					if (data.coverUrl) {
						this.coverUrl = data.coverUrl.split(',')
					} else {
						this.coverUrl = []
					}

					if (data.goodSentence) {
						this.goodSentence = data.goodSentence.split(',')
					} else {
						this.goodSentence = []
					}

					if (data.syllabusNew) {
						this.syllabusNew = data.syllabusNew.split(',')
					} else {
						this.syllabusNew = []
					}

					if (data.studyGuideUrl) {
						this.studyGuideUrl = data.studyGuideUrl.split(',')
					} else {
						this.studyGuideUrl = []
					}

					if (data.syllabus) {
						this.syllabus = data.syllabus.split(',')
					} else {
						this.syllabus = []
					}

					if (data.assistantQRCodeImg) {
						this.assistantQRCodeImg = data.assistantQRCodeImg.split(',')
					} else {
						this.assistantQRCodeImg = []
					}

					if (data.highlightsNew && data.highlightsNew.length > 0) {
						this.highlightsNew = data.highlightsNew
					}
					if (!data.unionCourse) {
						this.form.unionCourse = [
							{ courseNo: "0", price: 0, linePrice: 0 },
							{ courseNo: "0", price: 0, linePrice: 0 },
							{ courseNo: "0", price: 0, linePrice: 0 },
							{ courseNo: "0", price: 0, linePrice: 0 },
							{ courseNo: "0", price: 0, linePrice: 0 },
							{ courseNo: "0", price: 0, linePrice: 0 },
						];
					} else if (data.unionCourse.length < 6) {
						let lens = data.unionCourse.length
						for (var i = 1; i <= (6 - lens); i++) {
							this.form.unionCourse.push({ courseNo: "0", price: 0 , linePrice: 0 })
						}
					}
					if (!data.highlights) {
						this.form.highlights = "";
					}
				}
			});
		}
		this.teacherSelectFn();
		this.getAllLabelsByTypeFn();
		if (codeFn("courseTag")) this.isShowCourseTag = true;
	},
	components: {
		MyUpload,
		Ueditor,
		FooterToolBar,
		CropperUpload,
		detailCropperUpload: CropperUpload,
		UpdataImg
	},
	watch: {
		$route: {
			handler (to) {
				if (to.name == "addSourse") {
					document.body.scrollTop = 0;
				}
			},
			immediate: true,
		},
	},
	data () {
		return {
			locale,
			syllabus: [],
			syllabusNew: [],
			studyGuideUrl: [],
			goodSentence: [],
			assistantQRCodeImg: [],
			coverUrl: [],
			dateFormat: "YYYY-MM-DD HH:mm:ss",
			keepLoading: false, // 保存的按钮是否加载
			radioStyle: {
				display: "block",
				height: "30px",
				lineHeight: "30px",
			},
			radioStyle11: {
				height: "30px",
				lineHeight: "30px",
			},
			masterTeacherArr: [
				{ labelNo: "1335845498367643648", labelText: "覃流星" },
			], // 主讲老师数组
			labelsArr: [{ labelNo: "1371639096421781504", labelText: "test1" }], // 标签数组
			form: {
				id: "",
				name: "",
				conclusion: "",
				brief: "",
				commodityCode: "",
				coverUrl: "",
				coverCosKey: "",
				masterTeacherNo: undefined,
				detailsUrl: "",
				detailsCosKey: "",
				assistantDes: "",
				assistantQRCodeImg: "",
				whetherKind: 0,
				labels: [],
				courseType: 1,
				catalogueFlag: 1,
				description: "",
				pricingType: 1,
				price: 0,
				linePrice: 0,
				validDayCount: 0,
				studyCount: 0,
				putawayTime: null,
				enableFlag: 0,
				putawayFlag: 2,
				trillCommodityCode: "",
				titleBgColor: "#ff0000",
				suitableForPeople: "", //适合人群
				purchaseInstructions: "", //购买须知
				pairedReadingUrl: "", //伴读资料
				courseRem: "", //推荐列表
				goodSentence: "",
				highlights: "",
				syllabus: [],
				syllabusNew: [],
				wonderfulExcerpts: "",
				sort: 0,
				studyGuide: '',
				assistantTitle: '',
				unionCourse: [
					{ courseNo: "0", price: 0, linePrice: 0 },
					{ courseNo: "0", price: 0, linePrice: 0 },
					{ courseNo: "0", price: 0, linePrice: 0 },
					{ courseNo: "0", price: 0, linePrice: 0 },
					{ courseNo: "0", price: 0, linePrice: 0 },
					{ courseNo: "0", price: 0, linePrice: 0 },
				], //包含课程
			},

			highlightsNew: [
				{
					title: '',
					content: '',
					order: 1,
				}
			],

			rules: {
				name: [
					{
						required: true,
						message: "输入的内容不能为空",
						trigger: "blur",
					},
					{
						max: 20,
						message: "长度最长为20个",
						trigger: "blur",
					},
				],
				conclusion: [
					{
						required: true,
						message: "输入的内容不能为空",
						trigger: "blur",
					},
					{
						max: 22,
						message: "长度最大为22个",
						trigger: "blur",
					},
				],
				brief: [
					{
						required: true,
						message: "输入的内容不能为空",
						trigger: "blur",
					},
					{
						max: 300,
						message: "长度最大为300个",
						trigger: "blur",
					},
				],
				// highlights: [
				//     {
				//         required: true,
				//         message: "请输入亮点介绍",
				//         trigger: "blur",
				//     },
				// ],
				// goodSentence: [
				//   {
				//     required: true,
				//     message: "请输入好句分享",
				//     trigger: "blur",
				//   },
				// ],
				// WonderfulExcerpts: [
				//   {
				//     required: true,
				//     message: "请输入精彩选段",
				//     trigger: "blur",
				//   },
				// ],
				// syllabus: [
				//     {
				//         required: true,
				//         message: "请上传课程大纲",
				//         trigger: "blur",
				//     },
				// ],
				// syllabusNew: [
				//     {
				//         required: true,
				//         message: "请上传课程大纲",
				//         trigger: "blur",
				//     },
				// ],
				suitableForPeople: [
					{
						required: true,
						message: "请输入适合人群",
						trigger: "blur",
					},
				],
				purchaseInstructions: [
					{
						required: true,
						message: "请输入购买须知",
						trigger: "blur",
					},
				],
				coverUrl: [{ required: true, message: "请上传课程封面" }],
				masterTeacherNo: [
					{ required: true, message: "请选择主讲老师", trigger: "change" },
				],
				// detailsUrl: [{required: true, message: "请上传课程详情封面"}],
				labels: [{ required: true, message: "请上传课程标签" }],
				courseType: [{ required: true }],
				catalogueFlag: [{ required: true }],
				description: [{ required: true, message: "请上传课程详情" }],
				pricingType: [{ required: true }],
				enableFlag: [
					{
						required: true,
						message: "输入的内容不能为空",
						trigger: "change",
					},
				],
				putawayFlag: [{ required: true }],
				// wonderfulExcerpts: [{ required: true }],
			},
			isShowCourseTag: false, // 是否显示跳往标签页
			detailOptions: {
				autoCrop: true, //是否默认生成截图框
				autoCropWidth: 750, //默认生成截图框宽度
				autoCropHeight: 550, //默认生成截图框高度
				fixedBox: true, //是否固定截图框大小 不允许改变
				previewsCircle: false, //预览图是否是原圆形
				title: "修改图片",
			},
			coverOptions: {
				autoCrop: true, //是否默认生成截图框
				autoCropWidth: 174, //默认生成截图框宽度
				autoCropHeight: 200, //默认生成截图框高度
				fixedBox: true, //是否固定截图框大小 不允许改变
				previewsCircle: false, //预览图是否是原圆形
				title: "修改图片",
			},
			assistantQRCodeImgOptions: {
				autoCrop: false, //是否默认生成截图框
				autoCropWidth: 400, //默认生成截图框宽度
				autoCropHeight: 400, //默认生成截图框高度
				fixedBox: true, //是否固定截图框大小 不允许改变
				previewsCircle: false, //预览图是否是原圆形
				title: "修改图片",
			},
			syllabusOptions: {
				autoCrop: false, //是否默认生成截图框
				autoCropWidth: 751, //默认生成截图框宽度
				autoCropHeight: 2695, //默认生成截图框高度
				fixedBox: true, //是否固定截图框大小 不允许改变
				previewsCircle: false, //预览图是否是原圆形
				original: true,
				title: "修改图片",
			},
			goodSentenceOptions: {
				autoCrop: false, //是否默认生成截图框
				autoCropWidth: 375, //默认生成截图框宽度
				autoCropHeight: 495, //默认生成截图框高度
				fixedBox: true, //是否固定截图框大小 不允许改变
				previewsCircle: false, //预览图是否是原圆形
				title: "修改图片",
			},
		};
	},
	methods: {
		moment,
		// 新增 课程亮点操作- 新增
		highlightsNewClick () {
			let obj = {
				title: "",
				content: "",
				order: this.highlightsNew.length
			}
			this.highlightsNew.push(obj)
		},

		// 改变课程封面的图片路径
		coverUrlFn (data) {
			this.coverUrl = data;
			let str = data.toString()
			this.form.coverCosKey = str.slice(32, str.length)
		},
		// 主讲老师下拉选择框选中的回调
		masterTeacherChange (value) {
			this.masterTeacherNo = value;
		},
		// 改变课程详情封装的图片路径
		detailsUrlFn (data) {
			this.form.detailsUrl = data.url;
			this.form.detailsCosKey = data.cosKey;
		},
		// 助教二维码上传回调
		assistantQRCodeImgUrlFn (data) {
			this.assistantQRCodeImg = data;
		},
		// 好词分享上传回调
		goodSentenceUrlFn (data) {
			this.goodSentence = data
		},
		syllabusUrlFn (data) {
			this.syllabus = data
		},
		syllabusUrlFnNew (data) {
			this.syllabusNew = data
		},
		// 学习手册图片上传
		studyGuideUrlFnNew (data) {
			this.studyGuideUrl = data
		},
		// 课程标签改变的回调
		labelsChange (value) {
			this.form.labels = value;
		},
		// 课程类型改变的回调
		courseTypeChange (e) {
			this.form.courseType = e.target.value;
		},
		// 将输入的内容与显示的内容进行匹配
		filterOption (value, option) {
			return option.componentOptions.children[0].text.indexOf(value) >= 0;
		},
		// 标签刷新事件
		onRefresh () {
			this.form.labels = [];
			this.getAllLabelsByTypeFn();
		},
		// 是否分章节的改变的回调
		catalogueFlagChange (e) {
			this.form.catalogueFlag = e.target.value;
		},
		// 富文本的函数
		editorFn (value) {
			this.form.description = value;
		},
		// 亮点介绍的函数
		highlightseditorFn (value) {
			this.form.highlights = value;
		},
		// 精彩选段
		wonderfulExcerptseditorFn (value) {
			this.form.wonderfulExcerpts = value;
		},
		purchaseInstructionseditorFn (value) {
			this.form.purchaseInstructions = value;
		},
		suitableForPeopleeditorFn (value) {
			this.form.suitableForPeople = value;
		},
		// 定价方式改变的函数
		pricingTypeChange (e) {
			this.form.pricingType = e.target.value;
			if (e.target.value == 2 || e.target.value == 0) {
				this.form.price = 0
				this.form.linePrice = 0
			}
		},
		// 是否永久有效的回调
		enableFlagChange (e) {
			this.form.enableFlag = e.target.value;
		},
		// 上架设置改变的函数
		putawayFlagChange (e) {
			this.form.putawayFlag = e.target.value;
		},
		// 上架时间发生发生改变的回调
		putawayTimeChange (value, dateString) {
			this.form.putawayTime = value;
		},
		// 上架时间OK的回调
		putawayTimeOk (value) {
			this.form.putawayTime = value;
		},
		// 设置是否实物的函数
		whetherKindChange (e) {
			this.form.whetherKind = e.target.value;
		},
		// 保存的按钮
		keepClick () {
			this.form.putawayTime = format(
				new Date(this.form.putawayTime),
				"yyyy-MM-dd HH:mm:ss"
			);
			this.keepLoading = true;
			setTimeout(() => {
				this.keepLoading = false;
			}, 3000);
			if (this.form.linePrice == null) {
				this.form.linePrice = 0;
			}
			if (
				this.form.putawayTime == "1970-01-01 08:00:00" ||
				this.form.putawayTime == "NaN-aN-aN aN:aN:aN"
			) {
				this.form.putawayTime = "";
			}

			this.form.syllabus = this.syllabus.toString()
			this.form.syllabusNew = this.syllabusNew.toString()
			this.form.goodSentence = this.goodSentence.toString()
			this.form.assistantQRCodeImg = this.assistantQRCodeImg.toString()
			this.form.studyGuideUrl = this.studyGuideUrl.toString()
			this.form.coverUrl = this.coverUrl.toString()
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					if (this.form.putawayFlag != 0) {
						if (this.$route.params.id != 0) {
							this.form.highlightsNew = this.highlightsNew
							CourseInfoUpdCourseApiv2(this.form).then(({ code }) => {
								if (code == 200) {
									message.success("编辑成功");
									this.$router.push("/coursManage/courseList");
								}
							});
						} else {
							this.form.highlightsNew = this.highlightsNew
							CourseInfoSaveCourseApiv2(this.form).then(({ code }) => {
								if (code == 200) {
									message.success("新增成功");
									this.$router.push("/coursManage/courseList");
								}
							});
						}
					} else {
						message.error("请选择上架设置");
					}
				} else {
					message.error("还有必填项未完成");
				}
			});
		},
		// 封装获取老师列表的函数
		teacherSelectFn () {
			CourseTeacherSelectApi().then(({ code, data }) => {
				if (code == 200) {
					this.masterTeacherArr = data;
				}
			});
		},
		// 封装获取课程标签的函数
		getAllLabelsByTypeFn () {
			GetAllLabelsByTypeApi({ type: 1 }).then(({ code, data }) => {
				if (code == 200) {
					this.labelsArr = data;
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.aaa {
	float: left;
}

.marign_bottom24 {
	margin-bottom: 24px;
}

.marign_bottom0 {
	margin-bottom: 0;
}

.margin_left8 {
	margin-left: 8%;
}

.width100 {
	width: 150px;
}

.price {
	margin-left: 8%;

	span {
		&:nth-child(1) {
			margin-right: 50px;
		}

		.ant-input {
			width: 100px;
		}
	}
}

.tag-label-refresh {
	margin-top: 5px;
	position: absolute;
	right: -50px;
	color: #1890ff;
	cursor: pointer;
}

.tag-label-page {
	margin-top: 5px;
	position: absolute;
	right: -170px;
	color: #1890ff;
	cursor: pointer;
}

/deep/ .ant-form-item-control {
	position: relative;

	.count {
		color: #ccc;
		font-size: 10px;
		position: absolute;
		right: 5px;
		top: -12px;
	}

	.bind {
		position: absolute;
		color: #ccc;
		right: -110px;
		top: -8px;
	}
}

.study {
	margin-left: 8%;
	margin-bottom: 20px;
	color: #ccc;
}

/deep/ textarea.ant-input {
	resize: none;
}

.tips {
	margin-top: 10px;
	color: #ccc;
}

.listPs {
	display: flex;
	flex-direction: column;
}

.listP {
	display: flex;
	flex-wrap: nowrap;

	/deep/ .ant-form-item {
		display: flex;
		margin-left: 20px;

		.ant-form-item-label {
			width: 70px;
		}
	}
}
</style>
