<template>
  <!-- 新建banner页面 -->
  <div class="positionT0L0">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-form-model-item label="课时数" prop="courseClass" class="por">
        <a-input-number
          style="width: 200px"
          v-model="form.courseClass"
          :min="0"
          :max="99999"
          :precision="0"
          placeholder="请输入有效课时数"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="课程分类" prop="courseNo">
        <a-select
          show-search
          v-model="form.categoryNo"
          placeholder="点击选择课程"
          option-filter-prop="children"
          style="width: 200px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="changeLessontype"
        >
          <a-select-option
            :value="item.categoryNo"
            v-for="item in lessonArr"
            :key="item.categoryNo"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="课程封面"
        :class="{ 'has-error': !form.coverUrl && isShowImag }"
        required
      >
        <CropperUpload
          :options="coverOptions"
          :imageUrl="form.coverUrl"
          @avatarfn="imageUrlFn"
          avatarTextTop=""
          avatarTextBottom="点击新增/修改图片，推荐大小为218*310；上传图片不超过3M，格式为jpg，png。"
        />
        <div class="ant-form-explain" v-if="!form.coverUrl && isShowImag">
          请上传课程封面
        </div>
      </a-form-model-item>
      <a-form-model-item label="背景色值" prop="backgroundColor">
        <a-input style="width: 200px" v-model="form.backgroundColor"></a-input>
      </a-form-model-item>
    </a-form-model>

    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)"
        >返回</a-button
      >
      <a-button
        type="primary"
        @click="keepClick"
        :loading="keepLoading"
        :disabled="keepLoading"
        >保存</a-button
      >
    </FooterToolBar>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import { format } from "@/utils/tools";
import { GetCourseCategoryApi,SaveCourseInfoNew,GetCourseInfoNew } from "@/request/api/coursManage";
import CropperUpload from "@/components/file/CropperUpload";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar, CropperUpload },
  created() {
    GetCourseCategoryApi().then(({ code, data }) => {
      if (code == 200) this.lessonArr = data;
    });
     let id = this.$route.params.id;
    GetCourseInfoNew({
       courseNo: id
    }).then((res)=>{
      if (res.code == 200) {
        if(res.data.courseNo && res.data.courseNo !=""){
          this.form = res.data
        }
      }
    })
  },
  data() {
    return {
      isShowImag: false, // 是否显示图片提示
      keepLoading: false, // 保存的按钮是否加载
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      radioStyle11: {
        height: "30px",
        lineHeight: "30px",
      },
      form: {
        courseNo:this.$route.params.id,
        categoryNo: undefined,
        courseClass: "", //
        coverUrl: "",
        backgroundColor: "",
      },
      lessonArr: [],
      coverOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 218, //默认生成截图框宽度
        autoCropHeight: 310, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      },
      rules: {
        coverUrl: [
          {
            required: true,
            message: "请上传课程封面",
            trigger: "change",
          },
        ],
        courseNo: [
          {
            required: true,
            message: "请选择分类",
            trigger: "change",
          },
        ],
        backgroundColor: [
          {
            required: true,
            message: "请填写背景色",
            trigger: "blur",
          },
        ],
        courseClass: [
          {
            required: true,
            message: "请填写课时数",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    moment,
    // banner图片路径
    imageUrlFn(data) {
      this.form.coverUrl = data.url;
    },
    // 切换课程分类
    changeLessontype(value) {
      this.courseNo = value;
      console.log(value,888)
    },
    // 保存的按钮
    keepClick() {
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      if (!this.form.imageUrl) {
        this.isShowImag = true;
      }

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.courseClass=this.form.courseClass+''
          SaveCourseInfoNew(this.form).then(({ code }) => {
            if (code == 200) {
              message.success("添加成功");
              location.href = "/coursManage/courseList";
            }
          });
        } else {
          message.error("还有必填项未完成");
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.form_span {
  top: -4px;
}
/deep/.ant-radio-group {
  margin-bottom: 20px;
}
/deep/ .ant-form-item-control {
  line-height: unset;
}
.product-box {
  position: relative;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  .product-close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
  .product-wrap {
    height: 86px;
    .left-wrap {
      .product-img {
        margin-right: 10px;
        height: 86px;
        .p-img {
          height: 86px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .product-info {
        flex-direction: column;
        height: 86px;
        .p-title {
          margin-top: 6px;
          font-size: 14px;
          color: #000;
        }
        .p-price {
          color: red;
        }
        .p-type {
          color: #9fa5af;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .right-wrap {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
/deep/.product .ant-form-item-control {
  line-height: normal;
}
/deep/.edu-type .ant-form-item-children {
  display: flex;
}
/deep/.edu-type .ant-select:nth-child(2) {
  margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
  width: 100%;
}
/deep/.date-picker .ant-form-item-children {
  display: flex;
  align-items: center;
}
/deep/.ant-form-item-control {
  line-height: 22px;
}
</style>